<template>
    <!-- Start : Error panel -->
    <div class="alert alert-danger" role="alert" v-if="page_data.error.status">
        <i class="fas fa-bug fa-pull-right fa-border fa-4x" aria-hidden="true"></i>
        <h3>{{ page_data.error.status }} error</h3>
        <p>We're very sorry, but there was a problem:</p>
        <p class="error" v-html="$sanitize(page_data.error.data)"></p>
        <p>The page will retry automatically.</p>
    </div>
    <!-- End : Error panel -->

    <!-- Start : Logout warning -->
    <div class="alert alert-primary" role="alert" v-if="user.show_warning">
        <i class="fas fa-stopwatch fa-pull-right fa-border" aria-hidden="true"></i>
        Your session will timeout in 2 minutes
        <button type="button" class="btn btn-dark btn-sm" v-on:click="touch_login">
            Stay logged in
            <i class="fas fa-sign-in-alt" aria-hidden="true"></i>
        </button>
    </div>
    <!-- End : Logout warning -->

    <!-- Start : Debug -->
    <div class="container-fluid" v-if="debug.api">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb small">
                <li class="breadcrumb-item">
                    <strong>API</strong>
                </li>
                <li class="breadcrumb-item">
                    Fetch interval:
                    <code>{{ api.interval }}</code>
                </li>
                <li class="breadcrumb-item">
                    Paused?
                    <code>{{ api.paused }}</code>
                </li>
                <li class="breadcrumb-item">
                    Running?
                    <code>{{ api.running }}</code>
                </li>
                <li class="breadcrumb-item">
                    Loaded?
                    <code>{{ api.loaded }}</code>
                </li>
                <li class="breadcrumb-item">
                    Pulse:
                    <code>{{ api.pulse }}</code>
                </li>
                <li class="breadcrumb-item">
                    URL:
                    <code>{{ api.url }}</code>
                </li>
                <li class="breadcrumb-item">
                    Tab:
                    <code>{{ api.current_tab }}</code>
                </li>
                <li class="breadcrumb-item">
                    Error count:
                    <code>{{ api.error_count }}</code>
                </li>
                <li class="breadcrumb-item" v-if="api.error">
                    Error:
                    <code>{{ api.error }}</code>
                </li>
            </ol>
        </nav>
    </div>

    <div class="container-fluid" v-if="debug.user">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb small">
                <li class="breadcrumb-item">
                    <strong>User</strong>
                </li>
                <li class="breadcrumb-item">
                    ID:
                    <code>{{ user.id }}</code>
                </li>
                <li class="breadcrumb-item">
                    Humanised:
                    <code>{{ user.humanised_timestamps }}</code>
                </li>
                <li class="breadcrumb-item">
                    Session timeout (mins):
                    <code>{{ user.session_timeout }}</code>
                </li>
                <li class="breadcrumb-item">
                    Session time left (secs):
                    <code>{{ user.time_left }}</code>
                </li>
                <li class="breadcrumb-item">
                    Logout at:
                    <code>{{ user.logout_at }}</code>
                </li>
                <li class="breadcrumb-item">
                    Now:
                    <code>{{ user.now }}</code>
                </li>
                <li class="breadcrumb-item">
                    Warning at:
                    <code>{{ user.warning_at }}</code>
                </li>
                <li class="breadcrumb-item">
                    Warning in:
                    <code>{{ user.warning_in }}</code>
                </li>
                <li class="breadcrumb-item">
                    Show warning:
                    <code>{{ user.show_warning }}</code>
                </li>
            </ol>
        </nav>
    </div>

    <div class="container-fluid" v-if="debug.paging_and_filtering">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb small">
                <li class="breadcrumb-item">
                    <strong>Paging and filtering</strong>
                </li>
                <li class="breadcrumb-item">
                    Rows per page:
                    <code>{{ paging_and_filtering.rows_per_page }}</code>
                </li>
                <li class="breadcrumb-item">
                    Journeys current page:
                    <code>{{ paging_and_filtering.overview_current_page }}</code>
                </li>
                <li class="breadcrumb-item">
                    Signals current page:
                    <code>{{ paging_and_filtering.signals_current_page }}</code>
                </li>
                <li class="breadcrumb-item">
                    Steps current page:
                    <code>{{ paging_and_filtering.steps_current_page }}</code>
                </li>
                <li class="breadcrumb-item">
                    Quotes current page:
                    <code>{{ paging_and_filtering.quotes_current_page }}</code>
                </li>
                <li class="breadcrumb-item">
                    Transactions current page:
                    <code>{{ paging_and_filtering.transactions_current_page }}</code>
                </li>
                <li class="breadcrumb-item">
                    Filter:
                    <code>{{ paging_and_filtering.filter }}</code>
                </li>
                <li class="breadcrumb-item">
                    Filter shown:
                    <code>{{ paging_and_filtering.filter_shown }}</code>
                </li>
            </ol>
        </nav>
    </div>
    <!-- End: Debug-->

    <!-- Start: Main data grid | Small upwards -->
    <div class="container-fluid d-none d-sm-block">

        <div class="" id="tabs">
            <ul class="nav nav-tabs" id="tabbedInterface" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link btn-lg active" id="dashboard-tab" data-toggle="tab" data-target="#dashboard"
                        type="button" role="tab" aria-controls="dashboard" aria-selected="true" v-on:click="update_tab('')">
                        <i class="fas fa-tachometer-alt" aria-hidden="true"></i>
                        Dashboard
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button class="nav-link btn-lg" id="metrics-tab" data-toggle="tab" data-target="#metrics" type="button"
                        role="tab" aria-controls="metrics" aria-selected="true" v-on:click="update_tab('')">
                        <i class="fas fa-chart-line" aria-hidden="true"></i>
                        Metrics
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button class="nav-link btn-lg" id="search-tab" data-toggle="tab" data-target="#search" type="button"
                        role="tab" aria-controls="search" aria-selected="true" v-on:click="update_tab('')">
                        <i class="fab fa-google" aria-hidden="true"></i>
                        Search results
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button class="nav-link btn-lg" id="overview-tab" data-toggle="tab" data-target="#overview"
                        type="button" role="tab" aria-controls="overview" aria-selected="true"
                        v-on:click="update_tab('Journeys')">
                        <i class="fas fa-map" aria-hidden="true"></i>
                        Journeys
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button class="nav-link btn-lg" id="clicks-tab" data-toggle="tab" data-target="#clicks" type="button"
                        role="tab" aria-controls="clicks" aria-selected="true" v-on:click="update_tab('Steps')">
                        <i class="fas fa-shoe-prints" aria-hidden="true"></i>
                        Steps
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button class="nav-link btn-lg" id="quotes-tab" data-toggle="tab" data-target="#quotes" type="button"
                        role="tab" aria-controls="quotes" aria-selected="false" v-on:click="update_tab('Quotes')">
                        <i class="fas fa-list" aria-hidden="true"></i>
                        Quotes
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button class="nav-link btn-lg" id="transactions-tab" data-toggle="tab" data-target="#transactions"
                        type="button" role="tab" aria-controls="transactions" aria-selected="false"
                        v-on:click="update_tab('Transactions')">
                        <i class="fas fa-credit-card" aria-hidden="true"></i>
                        Transactions
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button class="nav-link btn-lg" id="charts-tab" data-toggle="tab" data-target="#statistics"
                        type="button" role="tab" aria-controls="statistics" aria-selected="false"
                        v-on:click="update_tab('Statistics')">
                        <i class="fas fa-chart-pie" aria-hidden="true"></i>
                        Statistics
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button class="nav-link btn-lg" id="profile-tab" data-toggle="tab" data-target="#profile" type="button"
                        role="tab" aria-controls="profile" aria-selected="false" v-on:click="update_tab('')">
                        <i class="fas fa-user" aria-hidden="true"></i>
                        Profile
                    </button>
                </li>

                <li class="nav-item ml-auto" :class="{ 'sale': show.help }" role="presentation">
                    <button class="nav-link btn-lg" data-toggle="button" type="button" aria-pressed="false"
                        @click="toggle_help">
                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                        {{ show.help ? 'Hide help' : 'Show help' }}
                    </button>
                </li>
            </ul>

            <div class="tab-content" id="tabbedInterfaceContent">

                <div class="tab-pane fade show active" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                    <iframe title="Sales" width="100%" height="680"
                        src="https://app.powerbi.com/reportEmbed?reportId=125fdb1f-9487-40a5-8a0e-6f1b7fb5ea46&autoAuth=true&ctid=3fd808a4-24c2-4305-a256-71abd113cd44&filterPaneEnabled=false"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>

                <div class="tab-pane fade" id="metrics" role="tabpanel" aria-labelledby="metrics-tab">
                    <iframe title="Sales" width="100%" height="680"
                        src="https://app.powerbi.com/reportEmbed?reportId=a6d7bc90-16e8-4797-be43-6df912d331a1&autoAuth=true&ctid=3fd808a4-24c2-4305-a256-71abd113cd44&filterPaneEnabled=false"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>

                <div class="tab-pane fade" id="overview" role="tabpanel" aria-labelledby="overview-tab">

                    <table class="table table-bordered table-hover table-sm table-striped" @mouseover="api.paused = true"
                        @mouseleave="api.paused = false">

                        <thead>

                            <tr v-if="show.help" class="fs-7">
                                <td colspan="99" class="table-info">
                                    <p>
                                        The Journeys tab shows your visitors moving through your customer journey from
                                        start to finish. The information updates real time. During data refeshes you
                                        will see the <i class="fas fa-spin fa-gear" aria-hidden="true"></i> icon. If you
                                        hover over any part of the data grid, you will pause automatic updates and the
                                        <i class="fas fa-pause" aria-hidden="true"></i> icon will be shown. Move the
                                        mouse cursor away from the data grid to resume automatic updates.
                                    </p>
                                    <p>
                                        The <em>Journey</em> column is in development and will show car, learner or van
                                        depending on the journey. The <em>Session ID</em> column shows the app session
                                        ID. This is the same ID stored in Strata. Clicking the ID, or any other link
                                        within any tab, will filter the results.
                                    </p>
                                    <p>
                                        You may see multiple IP addresses. The majority of these may be legitimate. For
                                        example, mobile networks may issue a new IP address mid-session and this is
                                        quite normal. You can use the markers to determine how "safe" the IP is:
                                    </p>
                                    <p>
                                        <span class="badge badge-pill badge-success">low</span> No security threat
                                        <span class="badge badge-pill badge-warning">medium</span> Medium security
                                        threat
                                        <span class="badge badge-pill badge-danger">high</span> High security threat
                                    </p>
                                    <p>
                                        Other markers will allow you to see the type of visitor:
                                    </p>
                                    <p>
                                        <i class="fas fa-spider" aria-hidden="true"></i> Web crawler
                                        <i class="fas fa-server" aria-hidden="true"></i> Proxy
                                        <i class="fas fa-user-secret" aria-hidden="true"></i> TOR
                                    </p>
                                    <p>
                                        The <span class="badge badge-pill bg-custom-info text-light">1</span> step
                                        markers indicate the number of times the customer has visited that particular
                                        step, and the <span class="badge badge-pill bg-custom-info text-light"><svg
                                                class="svg-inline--fa fa-arrow-right" aria-hidden="true" focusable="false"
                                                data-prefix="fas" data-icon="arrow-right" role="img"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                                                <path fill="currentColor"
                                                    d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z">
                                                </path>
                                            </svg></span> arrow indicates the direction of travel. Multiple visits are
                                        highlighted <span class="badge badge-pill bg-danger text-light">7</span> where
                                        they may warrant further investigation. For example, high numbers of visits to
                                        the quote page <em>might</em> be indicative of quote manipulation.
                                    </p>

                                </td>
                            </tr>

                            <tr class="fs-7 thead-dark">
                                <th scope="col" colspan="2" class="small" v-if="user.humanised_timestamps">Last seen </th>
                                <th scope="col" colspan="2" class="small" v-else>Timestamp</th>
                                <th scope="col" class="small">Journey</th>
                                <th scope="col" class="small">Session ID</th>
                                <th scope="col" class="small">IP addresses</th>
                                <th scope="col" class="small">Rating</th>
                                <th scope="col" class="small">Surname</th>
                                <th scope="col" class="small">Licence</th>
                                <th scope="col" class="small">Web reference</th>
                                <th scope="col" class="small">Source</th>
                                <th scope="col" class="small">Reg.</th>
                                <th scope="col" class="small">Man. vehicle</th>
                                <th scope="col" class="small">Cover</th>
                                <th scope="col" class="small">Driver</th>
                                <th scope="col" class="small">Driver details</th>
                                <th scope="col" class="small">Add. driver</th>
                                <th scope="col" class="small">Add. driver detail</th>
                                <th scope="col" class="small">Quotes</th>
                                <th scope="col" class="small">Cover conf.</th>
                                <th scope="col" class="small">Acc. criteria</th>
                                <th scope="col" class="small">Pay. form</th>
                                <th scope="col" class="small">Pay. outcome</th>
                                <th scope="col" class="small">Thank you</th>
                                <th scope="col" class="small">Seen for</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item) in page_data.overview_data" :key="item.row_id" class="fs-7"
                                :class="{ 'sale': item.step_14_count > 0 }" @mouseover="api.paused = true"
                                @mouseleave="api.paused = false">
                                <td class="small text-center width-30"><span v-if="api.paused && api.running == false"><i
                                            class="fas fa-pause" aria-hidden="true"></i></span><span v-if="api.running"><i
                                            class="fas fa-spin fa-gear" aria-hidden="true"></i></span></td>
                                <td class="small" v-if="user.humanised_timestamps">{{ item.last_seen_human }}</td>
                                <td class="small" v-else>{{ item.last_seen }}</td>
                                <td class="small text-center" v-html="$sanitize(item.module_fa_icon_html)">

                                </td>
                                <td class="small code text-nowrap">
                                    <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                        :data-filter="item.session_id" class="text-primary">
                                        <span v-if="item.session_id.startsWith('2') && !item.session_id.startsWith('2a')" class="green-dot"></span>
                                        <span v-if="item.session_id.startsWith('2a')" class="blue-dot"></span>
                                        {{
                                            item.session_id
                                        }}</a>
                                </td>
                                <td class="small">
                                    <table class="table table-sm no-bottom-margin no-bottom-padding">
                                        <tr v-for="(subitem) in item.ip_address_list" :key="subitem.ip_address">

                                            <td>
                                                <img :src="subitem.ip_country_flag" :alt="subitem.ip_country_name"
                                                    :title="subitem.ip_country_name" height="13" />
                                                    <!-- <span :title="subitem.ip_country_name">{{ subitem.ip_country_flag_emoji }}</span> -->
                                            </td>

                                            <td class="code">
                                                {{ subitem.ip_address }}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td class="small">
                                    <table class="table table-sm no-bottom-margin no-bottom-padding">
                                        <tr v-for="(subitem) in item.ip_address_list" :key="subitem.ip_address">
                                            <td class="tiny text-nowrap text-right">
                                                <span v-if="subitem.ip_is_crawler">
                                                    <i class="fas fa-spider" aria-hidden="true" alt="Crawler"
                                                        title="Crawler"></i>
                                                    &nbsp;
                                                </span>
                                                <span v-if="subitem.ip_is_proxy">
                                                    <i class="fas fa-server" aria-hidden="true" alt="Proxy"
                                                        title="Proxy"></i>
                                                    &nbsp;
                                                </span>
                                                <span v-if="subitem.ip_is_tor">
                                                    <i class="fas fa-user-secret" aria-hidden="true" alt="TOR"
                                                        title="TOR"></i>
                                                    &nbsp;
                                                </span>
                                                <span class="badge badge-pill tiny"
                                                    :class="{ 'badge-success': subitem.ip_threat_level === 'low', 'badge-warning': subitem.ip_threat_level === 'medium', 'badge-danger': subitem.ip_threat_level === 'high' }">
                                                    {{ subitem.ip_threat_level }}
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td class="small">
                                    <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                        :data-filter="item.surname" class="text-primary">{{
                                            item.surname
                                        }}</a>
                                </td>
                                <td class="small">
                                    <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                        :data-filter="item.licence_type" class="text-primary">{{
                                            item.licence_type
                                        }}</a>
                                </td>
                                <td class="small code">
                                    <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                        :data-filter="item.web_reference" class="text-primary">{{
                                            item.web_reference
                                        }}</a>
                                </td>
                                <td class="small code">
                                    <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                        :data-filter="item.source_code" class="text-primary">{{
                                            item.source_code
                                        }}</a>
                                </td>
                                <td class="small text-center" v-html="$sanitize(item.step_1_views)">

                                </td>
                                <td class="small text-center" v-html="$sanitize(item.step_2_views)">

                                </td>
                                <td class="small text-center" v-html="$sanitize(item.step_3_views)">

                                </td>
                                <td class="small text-center" v-html="$sanitize(item.step_4_views)">

                                </td>
                                <td class="small text-center" v-html="$sanitize(item.step_5_views)">

                                </td>
                                <td class="small text-center" v-if="item.step_6_views"
                                    v-html="$sanitize(item.step_6_views)">

                                </td>
                                <td class="small text-center" v-html="$sanitize(item.step_7_views)">

                                </td>
                                <td class="small text-center" v-html="$sanitize(item.step_8_views)">

                                </td>
                                <td class="small text-center" v-html="$sanitize(item.step_9_views)">

                                </td>
                                <td class="small text-center" v-html="$sanitize(item.step_10_views)">

                                </td>
                                <td class="small text-center" v-html="$sanitize(item.step_11_views)">

                                </td>
                                <td class="small text-center" v-html="$sanitize(item.step_13_views)">

                                </td>
                                <td class="small text-center" v-html="$sanitize(item.step_14_views)">

                                </td>
                                <td class="small text-center small" v-html="$sanitize(item.duration_human)">

                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="99">
                                    <button type="button" class="btn btn-dark btn-sm small"
                                        @click="paging('overview', 'previous', 1)">
                                        <i class="fas fa-angle-double-left" aria-hidden="true"></i>
                                    </button>
                                    &nbsp;
                                    <button type="button" class="btn btn-dark btn-sm small"
                                        @click="paging('overview', 'previous', null)">
                                        <i class="fas fa-angle-left" aria-hidden="true"></i>
                                        Back
                                    </button>
                                    &nbsp;
                                    <button type="button" class="btn btn-dark btn-sm small"
                                        @click="paging('overview', 'next', null)">
                                        Next
                                        <i class="fas fa-angle-right" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div class="tab-pane fade" id="search" role="tabpanel" aria-labelledby="search-tab">
                    <iframe title="Search results" width="100%" height="680"
                        src="https://app.powerbi.com/reportEmbed?reportId=8b3dc1b8-8816-4048-9e84-980788e832d7&autoAuth=true&ctid=3fd808a4-24c2-4305-a256-71abd113cd44&filterPaneEnabled=false"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>

                <div class="tab-pane fade show" id="clicks" role="tabpanel" aria-labelledby="clicks-tab">
                    <table class="table table-bordered table-hover table-sm table-striped" @mouseover="api.paused = true"
                        @mouseleave="api.paused = false">
                        <thead>

                            <tr v-if="show.help" class="fs-7">
                                <td colspan="99" class="table-info">
                                    <p>
                                        This page is designed to allow you to view all steps in the Quote and Buy
                                        process. If you have clicked the session ID on the Quotes page it will only
                                        display the steps for that particular customer journey.
                                    </p>

                                    <p>
                                        You can also click the session ID on this page to to limit the view to
                                        that particular customer, or click any other link to filter by that column.
                                    </p>

                                    <div class="alert alert-info" role="alert">
                                        <i class="fas fa-exclamation-circle" aria-hidden="true"></i> If you have set a
                                        filter, click <em>Clear filter</em> at the top of the page to remove it.
                                    </div>
                                </td>
                            </tr>

                            <tr class="fs-7 thead-dark">
                                <th scope="col" class="small" colspan="2" v-if="user.humanised_timestamps">Seen</th>
                                <th scope="col" class="small" colspan="2" v-else>Timestamp</th>
                                <th scope="col" class="small">Journey</th>
                                <th scope="col" class="small">Session ID</th>
                                <th scope="col" class="small">Surname</th>
                                <th scope="col" class="small">Web reference</th>
                                <th scope="col" class="small">Step</th>
                                <th scope="col" class="small">Step GUID</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item) in page_data.steps_data" :key="item.row_id" class="fs-7"
                                :class="{ 'sale': item.thanks_page }" @mouseover="api.paused = true"
                                @mouseleave="api.paused = false">
                                <td class="small text-center width-30"><span v-if="api.paused && api.running == false"><i
                                            class="fas fa-pause" aria-hidden="true"></i></span><span v-if="api.running"><i
                                            class="fas fa-spin fa-gear" aria-hidden="true"></i></span></td>
                                <td class="small" v-if="user.humanised_timestamps">{{ item.last_seen_human }}</td>
                                <td class="small" v-else>{{ item.last_seen }}</td>
                                <td class="small text-center" v-html="$sanitize(item.fa_icon_html)">

                                </td>
                                <td class="small code text-nowrap">
                                    <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                        :data-filter="item.session_id" class="text-primary">{{
                                            item.session_id
                                        }}</a>
                                </td>
                                <td class="small">
                                    <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                        :data-filter="item.surname" class="text-primary">{{
                                            item.surname
                                        }}</a>
                                </td>
                                <td class="small code">
                                    <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                        :data-filter="item.web_reference" class="text-primary">{{
                                            item.web_reference
                                        }}</a>
                                </td>
                                <td class="small text-nowrap">{{ item.step_description }}</td>
                                <td class="small code text-nowrap">
                                    <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                        :data-filter="item.step_guid" class="text-primary">{{ item.step_guid }}</a>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="99">
                                    <button type="button" class="btn btn-dark btn-sm small"
                                        @click="paging('steps', 'previous', 1)">
                                        <i class="fas fa-angle-double-left" aria-hidden="true"></i>
                                    </button>
                                    &nbsp;
                                    <button type="button" class="btn btn-dark btn-sm small"
                                        @click="paging('steps', 'previous', null)">
                                        <i class="fas fa-angle-left" aria-hidden="true"></i>
                                        Back
                                    </button>
                                    &nbsp;
                                    <button type="button" class="btn btn-dark btn-sm small"
                                        @click="paging('steps', 'next', null)">
                                        Next
                                        <i class="fas fa-angle-right" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div class="tab-pane fade" id="quotes" role="tabpanel" aria-labelledby="quotes-tab">
                    <table class="table table-bordered table-hover table-sm" @mouseover="api.paused = true"
                        @mouseleave="api.paused = false">

                        <tr v-if="show.help" class="fs-7">
                            <td colspan="99" class="table-info">
                                <p>
                                    This page shows all of the quotes returned to the customers. To filter results, use
                                    the links at the bottom of each quote result "card".
                                </p>

                                <p>
                                    At present, the GoShorty app shows all quotes, and you can look at the
                                    <em>Quoted?</em> column to determine whether the quote was available to buy. If the
                                    customer chooses an insurer, this will be shown in the <em>Selected?</em>. Post
                                    sale, this is shown in the <em>Sold?</em> column.
                                </p>

                                <p>
                                    Cards are "striped" in order to show the relationship between cards. They will
                                    alternate unless the following quote is for the same session, in which case the row
                                    back colour will remain the same.
                                </p>
                            </td>
                        </tr>

                        <tbody>
                            <template v-for="(item) in page_data.quotes_data" :key="item.row_id">

                                <tr v-if="item.position == 1 || paging_and_filtering.filter == item.insurer_code || paging_and_filtering.filter == item.scheme_code"
                                    @mouseover="api.paused = true" @mouseleave="api.paused = false"
                                    class="table-secondary new-quote"
                                    :class="[{ 'selected_quote': item.selected_by_customer }, item.background_class]">
                                    <th scope="col" colspan="99" class="small">
                                        <i class="fas fa-fw fa-clock" aria-hidden="true"></i>
                                        &nbsp;
                                        <span v-if="user.humanised_timestamps">{{ item.last_seen_human }}</span>
                                        <span v-else>{{ item.last_seen }}</span>
                                        &nbsp;
                                        <i class="fas fa-fw fa-database" aria-hidden="true"></i>
                                        &nbsp;
                                        <span>{{ item.web_reference }}</span>
                                        &nbsp;
                                        <i class="fas fa-fw fa-umbrella" aria-hidden="true"></i>
                                        &nbsp;
                                        <span>{{ item.cover_reason_mapped }} for {{ item.duration }}</span>
                                        &nbsp;
                                        <i class="fas fa-fw fa-user" aria-hidden="true"></i>
                                        &nbsp;
                                        <span>{{ item.gender_full }}, {{ item.age }} years old</span>
                                        &nbsp;
                                        <i class="fas fa-fw fa-car" aria-hidden="true"></i>
                                        &nbsp;
                                        <span>{{ item.year }} {{ item.make }} {{ item.model }} worth {{ item.value
                                        }}</span>
                                        &nbsp;
                                        <i class="fas fa-fw fa-map-marker-alt" aria-hidden="true"></i>
                                        &nbsp;
                                        <span>{{ item.postcode }}</span>
                                    </th>
                                </tr>

                                <tr v-if="item.position == 1 || item.filtered == 1 && paging_and_filtering.filter == item.scheme_code"
                                    class="fs-7 thead-dark small">
                                    <th scope="col">&nbsp;</th>
                                    <th scope="col" class="small">Position</th>
                                    <th scope="col" class="small">Premium</th>
                                    <th scope="col" class="small">Increase</th>
                                    <th scope="col" class="small">Increase %</th>
                                    <th scope="col" class="small">Admin fee</th>
                                    <th scope="col" class="small">Insurer code</th>
                                    <th scope="col" class="small">Insurer</th>
                                    <th scope="col" class="small">Scheme code</th>
                                    <th scope="col" class="small">Scheme</th>
                                    <th scope="col" class="small">Quoted?</th>
                                    <th scope="col" class="small">Selected?</th>
                                    <th scope="col" class="small">Sold?</th>
                                    <th scope="col" class="small">Reason</th>
                                    <th scope="col" class="small">Quotes returned</th>
                                    <th scope="col" class="small">Quotes displayed</th>
                                    <th scope="col" class="small">Maximum premium</th>
                                    <th scope="col" class="small">Minimum premium</th>
                                </tr>

                                <tr class="small" @mouseover="api.paused = true" @mouseleave="api.paused = false"
                                    :class="[{ 'selected_quote': item.selected_by_customer, 'sale': item.sold }, item.background_class]">
                                    <td class="small text-center width-30"><span
                                            v-if="api.paused && api.running == false"><i class="fas fa-pause"
                                                aria-hidden="true"></i></span><span v-if="api.running"><i
                                                class="fas fa-spin fa-gear" aria-hidden="true"></i></span></td>
                                    <td class="small text-center"><strong>{{ item.position }}</strong></td>
                                    <td class="small text-right">{{ item.premium }}</td>
                                    <td class="small text-right">{{ item.increase }}</td>
                                    <td class="small text-right">{{ item.increase_percentage }}</td>
                                    <td class="small text-right">{{ item.admin_fee }}</td>
                                    <td class="small text-nowrap">
                                        <span class="code">
                                            <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                                :data-filter="item.insurer_code" class="text-primary">
                                                {{ item.insurer_code }}
                                            </a>
                                        </span>
                                    </td>
                                    <td class="small">{{ item.insurer_description }}</td>
                                    <td class="small text-nowrap">
                                        <span class="code">
                                            <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                                :data-filter="item.scheme_code" class="text-primary">
                                                {{
                                                    item.scheme_code
                                                }}
                                            </a>
                                        </span>
                                    </td>
                                    <td class="small">{{ item.scheme_description }}</td>
                                    <td v-html="$sanitize(item.quoted_html)" class="small text-center">

                                    </td>
                                    <td v-html="$sanitize(item.selected_by_customer_html)" class="small text-center">

                                    </td>
                                    <td v-html="$sanitize(item.sold_html)" class="small text-center">

                                    </td>
                                    <td class="small">{{ item.quote_reason }}</td>
                                    <td class="small text-right">{{ item.quotes_returned }}</td>
                                    <td class="small text-right">{{ item.quotes_displayed }}</td>
                                    <td class="small text-right">{{ item.maximum_premium }}</td>
                                    <td class="small text-right">{{ item.minimum_premium }}</td>
                                </tr>

                                <tr v-if="item.position == item.quotes_returned" class="last-quote-row">
                                    <td colspan="99" class="small">
                                        <nav aria-label="breadcrumb" class="no-bottom-margin no-bottom-padding">
                                            <ol class="breadcrumb small no-bottom-margin">
                                                <li class="breadcrumb-item active" aria-current="page"><strong>Filter
                                                        all quotes</strong>
                                                </li>
                                                <li class="breadcrumb-item active" aria-current="page">
                                                    Surname:
                                                    <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                                        :data-filter="item.surname" class="text-primary">{{
                                                            item.surname
                                                        }}</a>
                                                </li>
                                                <li class="breadcrumb-item active" aria-current="page">
                                                    Duration:
                                                    <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                                        :data-filter="item.duration" class="text-primary">{{
                                                            item.duration
                                                        }}</a>
                                                </li>
                                                <li class="breadcrumb-item active" aria-current="page">
                                                    Postcode:
                                                    <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                                        :data-filter="item.postcode" class="text-primary">{{
                                                            item.postcode
                                                        }}</a>
                                                </li>
                                                <li class="breadcrumb-item active" aria-current="page">
                                                    Reason for cover:
                                                    <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                                        :data-filter="item.cover_reason_mapped" class="text-primary">{{
                                                            item.cover_reason_mapped
                                                        }}</a>
                                                </li>
                                                <li class="breadcrumb-item active" aria-current="page">
                                                    Session ID:
                                                    <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                                        :data-filter="item.session_id" class="text-primary">{{
                                                            item.session_id
                                                        }}</a>
                                                </li>
                                                <li class="breadcrumb-item active" aria-current="page">
                                                    Web reference:
                                                    <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                                        :data-filter="item.web_reference" class="text-primary">{{
                                                            item.web_reference
                                                        }}</a>
                                                </li>
                                            </ol>
                                        </nav>
                                    </td>
                                </tr>

                            </template>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="99">
                                    <button type="button" class="btn btn-dark btn-sm small"
                                        @click="paging('quotes', 'previous', 1)">
                                        <i class="fas fa-angle-double-left" aria-hidden="true"></i>
                                    </button>
                                    &nbsp;
                                    <button type="button" class="btn btn-dark btn-sm small"
                                        @click="paging('quotes', 'previous', null)">
                                        <i class="fas fa-angle-left" aria-hidden="true"></i>
                                        Back
                                    </button>
                                    &nbsp;
                                    <button type="button" class="btn btn-dark btn-sm small"
                                        @click="paging('quotes', 'next', null)">
                                        Next
                                        <i class="fas fa-angle-right" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div class="tab-pane fade" id="transactions" role="tabpanel" aria-labelledby="transactions-tab">

                    <div class="row no-bottom-margin no-bottom-padding">

                        <div class="col-md-9">

                            <table class="table table-bordered table-hover table-sm table-striped"
                                @mouseover="api.paused = true" @mouseleave="api.paused = false">
                                <thead>
                                    <tr v-if="show.help" class="fs-7">
                                        <td colspan="99" class="table-info">
                                            <p>
                                                This page shows a list of <em>all</em> attempted transactions and their
                                                outcomes. Where a payment was not successful the reason will be
                                                displayed.
                                            </p>

                                            <p>
                                                Transaction codes 1 and 2 are the WorldPay outcome codes where
                                                <em>COMPLETED</em> plus <em>AUTHORISED</em> is considered a successful
                                                outcome.
                                            </p>
                                        </td>
                                    </tr>

                                    <tr class="fs-7 thead-dark">
                                        <th scope="col" class="small" colspan="2" v-if="user.humanised_timestamps">Seen
                                        </th>
                                        <th scope="col" class="small" colspan="2" v-else>Timestamp</th>
                                        <th scope="col" class="small">Journey</th>
                                        <th scope="col" class="small">Session ID</th>
                                        <th scope="col" class="small">Surname</th>
                                        <th scope="col" class="small">Web reference</th>
                                        <!-- <th scope="col" class="small">Platform</th> -->
                                        <th scope="col" class="small">Trans. code 1</th>
                                        <th scope="col" class="small">Trans. code 2</th>
                                        <!-- <th scope="col" class="small">Trans. code 3</th> -->
                                        <th scope="col" class="small">Outcome</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(item) in page_data.transactions_data" :key="item.row_id" class="fs-7"
                                        @mouseover="api.paused = true" @mouseleave="api.paused = false"
                                        :class="{ 'sale': item.transaction_code_2 === 'AUTHORISED' || item.transaction_code_3 === '000.000.000' }">
                                        <td class="small text-center width-30"><span
                                                v-if="api.paused && api.running == false"><i class="fas fa-pause"
                                                    aria-hidden="true"></i></span><span v-if="api.running"><i
                                                    class="fas fa-spin fa-gear" aria-hidden="true"></i></span></td>
                                        <td class="small" v-if="user.humanised_timestamps">{{ item.last_seen_human }}
                                        </td>
                                        <td class="small" v-else>{{ item.last_seen }}</td>
                                        <td class="small text-center" v-html="$sanitize(item.fa_icon_html)">

                                        </td>
                                        <td class="code text-nowrap tiny">
                                            <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                                :data-filter="item.session_id" class="text-primary">{{
                                                    item.session_id
                                                }}</a>
                                        </td>
                                        <td class="small">
                                            <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                                :data-filter="item.surname" class="text-primary">{{
                                                    item.surname
                                                }}</a>
                                        </td>
                                        <td class="small code">
                                            <a href="Javascript:void(0);" v-on:click="set_filter_by_attribute"
                                                :data-filter="item.web_reference" class="text-primary">{{
                                                    item.web_reference
                                                }}</a>
                                        </td>
                                        <!-- <td class="small text-center">
                                            <img v-bind:src="require('@/assets/' + item.platform_icon + '.jpg')"
                                                v-bind:alt="item.platform" v-bind:title="item.platform" width="18" />
                                        </td> -->
                                        <td class="small"><code>{{ item.transaction_code_1 }}</code></td>
                                        <td class="small"><code>{{ item.transaction_code_2 }}</code></td>
                                        <!-- <td class="small"><code>{{ item.transaction_code_3 }}</code></td> -->
                                        <td class="small">{{ item.transaction_outcome }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="99">
                                            <button type="button" class="btn btn-dark btn-sm small"
                                                @click="paging('transactions', 'previous', 1)">
                                                <i class="fas fa-angle-double-left" aria-hidden="true"></i>
                                            </button>
                                            &nbsp;
                                            <button type="button" class="btn btn-dark btn-sm small"
                                                @click="paging('transactions', 'previous', null)">
                                                <i class="fas fa-angle-left" aria-hidden="true"></i>
                                                Back
                                            </button>
                                            &nbsp;
                                            <button type="button" class="btn btn-dark btn-sm small"
                                                @click="paging('transactions', 'next', null)">
                                                Next
                                                <i class="fas fa-angle-right" aria-hidden="true"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>

                        <div class="col-md-3">

                            <table class="table table-bordered table-hover table-sm table-striped">

                                <thead>
                                    <tr class="fs-7 thead-dark">
                                        <th scope="col" class="small"></th>
                                        <!-- <th scope="col" class="small">Platform</th> -->
                                        <th scope="col" class="small">Trans. code 1</th>
                                        <th scope="col" class="small">Trans. code 2</th>
                                        <!-- <th scope="col" class="small">Trans. code 3</th> -->
                                        <th scope="col" class="small">Total</th>
                                        <th scope="col" class="small">%age</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(item) in page_data.sidebars.transactions_data" :key="item.id" class="fs-7"
                                        :class="{ 'sale': item.transaction_code_2 === 'AUTHORISED' || item.transaction_code_3 === '000.000.000' }">
                                        <td class="small text-center" v-html="$sanitize(item.fa_icon_html)">

                                        </td>
                                        <!-- <td class="small text-center">
                                            <img v-bind:src="require('@/assets/' + item.platform_icon + '.jpg')"
                                                v-bind:alt="item.platform" v-bind:title="item.platform" width="15" />
                                        </td> -->

                                        <td class="small"><code>{{ item.transaction_code_1 }}</code></td>
                                        <td class="small"><code>{{ item.transaction_code_2 }}</code></td>
                                        <!-- <td class="small"><code>{{ item.transaction_code_3 }}</code></td> -->
                                        <td class="small text-right"><strong>{{ item.total }}</strong></td>
                                        <td class="small text-right"><strong>{{ item.percentage }}</strong></td>
                                    </tr>
                                </tbody>

                                <tfoot>
                                    <td colspan="99" class="small text-center bg-secondary text-light">
                                        <i class="fas fa-lg fa-history" aria-hidden="true"></i>
                                        24 hours
                                    </td>
                                </tfoot>

                            </table>
                        </div>

                    </div>

                </div>

                <div class="tab-pane fade" id="statistics" role="tabpanel" aria-labelledby="statistics-tab">

                    <div>
                        &nbsp;
                    </div>

                    <div class="container-fluid">

                        <div class="alert alert-info fs-7" v-if="show.help" role="alert">
                            <p>
                                We are working on the statistics tab, and currently we show the insurer panel share over
                                all time and the last 24 hours.
                            </p>

                            <p>
                                These real time reports are designed to supplement more in depth Power BI reports and
                                dashboards.
                            </p>
                        </div>

                    </div>

                    <div class="card text-center">
                        <div class="card-header">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="statistics-insurer-share-tab" data-toggle="tab"
                                        href="#statistics-insurer-share" role="tab" aria-controls="statistics-insurer-share"
                                        aria-selected="true">
                                        <i class="fas fa-pizza-slice" aria-hidden="true"></i>
                                        Insurer share
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="statistics-declines-tab" data-toggle="tab"
                                        href="#statistics-declines" role="tab" aria-controls="statistics-declines"
                                        aria-selected="false">
                                        <i class="fas fa-ban" aria-hidden="true"></i>
                                        Declines
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="statistics-durations-tab" data-toggle="tab"
                                        href="#statistics-durations" role="tab" aria-controls="statistics-durations"
                                        aria-selected="false">
                                        <i class="fas fa-hourglass-half" aria-hidden="true"></i>
                                        Durations
                                    </a>
                                </li>
                            </ul>

                            <!-- Tab panes -->
                            <div class="tab-content">

                                <div class="tab-pane active" id="statistics-insurer-share" role="tabpanel"
                                    aria-labelledby="statistics-insurer-share-tab">

                                    <div class="card-body">

                                        <div class="row">

                                            <div class="col-1">
                                                <h4>Last hour</h4>
                                            </div>

                                            <div class="col-11">

                                                <table
                                                    class="table table-bordered table-hover table-sm table-striped no-bottom-margin">

                                                    <thead>
                                                        <tr class="small thead-dark">
                                                            <th scope="col" class="small">Insurer</th>
                                                            <th scope="col" class="small">Quotes</th>
                                                            <th scope="col" class="small">Avg. pos.</th>
                                                            <th scope="col" class="small">Min. prem.</th>
                                                            <th scope="col" class="small">Max. prem.</th>
                                                            <th scope="col" class="small">Avg. prem.</th>
                                                            <th scope="col" class="small">Avg. fee</th>
                                                            <th colspan="2" scope="col" class="small">Of quotes</th>
                                                            <th scope="col" class="small">Sales</th>
                                                            <th scope="col" class="small">Avg. sold position</th>
                                                            <th scope="col" class="small">Min. sold premium</th>
                                                            <th scope="col" class="small">Max. sold premium</th>
                                                            <th scope="col" class="small">Avg. sold premium</th>
                                                            <th scope="col" class="small">Avg. sold fee</th>
                                                            <th colspan="2" scope="col" class="small">Of sales</th>
                                                            <th scope="col" class="small">Earnings</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="(item) in page_data.statistics.insurer_share_last_hour_data"
                                                            :key="item.insurer_description" class="small">
                                                            <td class="small"><strong>{{ item.insurer_description
                                                            }}</strong>
                                                            </td>
                                                            <td class="small text-right">{{ item.quotes }}</td>
                                                            <td class="small text-right">{{ item.avg_position }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.min_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.max_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.avg_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_admin_fee
                                                            }}
                                                            </td>
                                                            <td class="small text-right">{{
                                                                item.insurer_pcage_of_quotes
                                                            }}%
                                                            </td>
                                                            <td class="small text-right progress-bar-wrapper"
                                                                v-html="item.insurer_pcage_of_quotes_html"></td>
                                                            <td class="small text-right">{{ item.sales }}</td>
                                                            <td class="small text-right">{{ item.avg_sold_position
                                                            }}</td>
                                                            <td class="small text-right">&pound;{{
                                                                item.min_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.max_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_sold_admin_fee
                                                            }} </td>
                                                            <td class="small text-right">{{
                                                                item.insurer_pcage_of_sales
                                                            }}%
                                                            </td>
                                                            <td class="small text-right progress-bar-wrapper"
                                                                v-html="item.insurer_pcage_of_sales_html"></td>
                                                            <td class="small text-right"><strong>&pound;{{
                                                                item.earnings
                                                            }}</strong></td>

                                                        </tr>
                                                    </tbody>

                                                </table>

                                            </div>

                                        </div>

                                    </div>

                                    <div class="card-body">

                                        <div class="row">

                                            <div class="col-1">
                                                <h4>Today</h4>
                                            </div>

                                            <div class="col-11">

                                                <table
                                                    class="table table-bordered table-hover table-sm table-striped no-bottom-margin">

                                                    <thead>
                                                        <tr class="thead-dark small">
                                                            <th scope="col" class="small">Insurer</th>
                                                            <th scope="col" class="small">Quotes</th>
                                                            <th scope="col" class="small">Avg. pos.</th>
                                                            <th scope="col" class="small">Min. prem.</th>
                                                            <th scope="col" class="small">Max. prem.</th>
                                                            <th scope="col" class="small">Avg. prem.</th>
                                                            <th scope="col" class="small">Avg. fee</th>
                                                            <th colspan="2" scope="col" class="small">Of quotes</th>
                                                            <th scope="col" class="small">Sales</th>
                                                            <th scope="col" class="small">Avg. sold position</th>
                                                            <th scope="col" class="small">Min. sold premium</th>
                                                            <th scope="col" class="small">Max. sold premium</th>
                                                            <th scope="col" class="small">Avg. sold premium</th>
                                                            <th scope="col" class="small">Avg. sold fee</th>
                                                            <th colspan="2" scope="col" class="small">Of sales</th>
                                                            <th scope="col" class="small">Earnings</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="(item) in page_data.statistics.insurer_share_today_data"
                                                            :key="item.insurer_description" class="small">
                                                            <td class="small"><strong>{{ item.insurer_description
                                                            }}</strong>
                                                            </td>
                                                            <td class="small text-right">{{ item.quotes }}</td>
                                                            <td class="small text-right">{{ item.avg_position }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.min_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.max_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.avg_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_admin_fee
                                                            }}
                                                            </td>
                                                            <td class="small text-right">{{
                                                                item.insurer_pcage_of_quotes
                                                            }}%
                                                            </td>
                                                            <td class="small text-right progress-bar-wrapper"
                                                                v-html="item.insurer_pcage_of_quotes_html"></td>
                                                            <td class="small text-right">{{ item.sales }}</td>
                                                            <td class="small text-right">{{ item.avg_sold_position
                                                            }}</td>
                                                            <td class="small text-right">&pound;{{
                                                                item.min_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.max_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_sold_admin_fee
                                                            }} </td>
                                                            <td class="small text-right">{{
                                                                item.insurer_pcage_of_sales
                                                            }}%</td>
                                                            <td class="tiny text-right progress-bar-wrapper"
                                                                v-html="item.insurer_pcage_of_sales_html"></td>
                                                            <td class="small text-right"><strong>&pound;{{
                                                                item.earnings
                                                            }}</strong></td>

                                                        </tr>
                                                    </tbody>

                                                </table>

                                            </div>

                                        </div>

                                    </div>


                                    <div class="card-body">

                                        <div class="row">

                                            <div class="col-1">
                                                <h4>24 hours</h4>
                                            </div>

                                            <div class="col-11">

                                                <table
                                                    class="table table-bordered table-hover table-sm table-striped no-bottom-margin">

                                                    <thead>
                                                        <tr class="small thead-dark">
                                                            <th scope="col" class="small">Insurer</th>
                                                            <th scope="col" class="small">Quotes</th>
                                                            <th scope="col" class="small">Avg. pos.</th>
                                                            <th scope="col" class="small">Min. prem.</th>
                                                            <th scope="col" class="small">Max. prem.</th>
                                                            <th scope="col" class="small">Avg. prem.</th>
                                                            <th scope="col" class="small">Avg. fee</th>
                                                            <th colspan="2" scope="col" class="small">Of quotes</th>
                                                            <th scope="col" class="small">Sales</th>
                                                            <th scope="col" class="small">Avg. sold position</th>
                                                            <th scope="col" class="small">Min. sold premium</th>
                                                            <th scope="col" class="small">Max. sold premium</th>
                                                            <th scope="col" class="small">Avg. sold premium</th>
                                                            <th scope="col" class="small">Avg. sold fee</th>
                                                            <th colspan="2" scope="col" class="small">Of sales</th>
                                                            <th scope="col" class="small">Earnings</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="(item) in page_data.statistics.insurer_share_24_hours_data"
                                                            :key="item.insurer_description" class="small">
                                                            <td class="small"><strong>{{ item.insurer_description
                                                            }}</strong>
                                                            </td>
                                                            <td class="small text-right">{{ item.quotes }}</td>
                                                            <td class="small text-right">{{ item.avg_position }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.min_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.max_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.avg_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_admin_fee
                                                            }}
                                                            </td>
                                                            <td class="small text-right">{{
                                                                item.insurer_pcage_of_quotes
                                                            }}%
                                                            </td>
                                                            <td class="small text-right progress-bar-wrapper"
                                                                v-html="item.insurer_pcage_of_quotes_html"></td>
                                                            <td class="small text-right">{{ item.sales }}</td>
                                                            <td class="small text-right">{{ item.avg_sold_position
                                                            }}</td>
                                                            <td class="small text-right">&pound;{{
                                                                item.min_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.max_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_sold_admin_fee
                                                            }} </td>
                                                            <td class="small text-right">{{
                                                                item.insurer_pcage_of_sales
                                                            }}%
                                                            </td>
                                                            <td class="small text-right progress-bar-wrapper"
                                                                v-html="item.insurer_pcage_of_sales_html"></td>
                                                            <td class="small text-right"><strong>&pound;{{
                                                                item.earnings
                                                            }}</strong></td>

                                                        </tr>

                                                    </tbody>

                                                </table>

                                            </div>

                                        </div>

                                    </div>

                                    <div class="card-body">

                                        <div class="row">

                                            <div class="col-1">
                                                <h4>All time</h4>
                                            </div>

                                            <div class="col-11">

                                                <table
                                                    class="table table-bordered table-hover table-sm table-striped no-bottom-margin">

                                                    <thead>
                                                        <tr class="thead-dark small">
                                                            <th scope="col" class="small">Insurer</th>
                                                            <th scope="col" class="small">Quotes</th>
                                                            <th scope="col" class="small">Avg. pos.</th>
                                                            <th scope="col" class="small">Min. prem.</th>
                                                            <th scope="col" class="small">Max. prem.</th>
                                                            <th scope="col" class="small">Avg. prem.</th>
                                                            <th scope="col" class="small">Avg. fee</th>
                                                            <th colspan="2" scope="col" class="small">Of quotes</th>
                                                            <th scope="col" class="small">Sales</th>
                                                            <th scope="col" class="small">Avg. sold position</th>
                                                            <th scope="col" class="small">Min. sold premium</th>
                                                            <th scope="col" class="small">Max. sold premium</th>
                                                            <th scope="col" class="small">Avg. sold premium</th>
                                                            <th scope="col" class="small">Avg. sold fee</th>
                                                            <th colspan="2" scope="col" class="small">Of sales</th>
                                                            <th scope="col" class="small">Earnings</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="(item) in page_data.statistics.insurer_share_all_time_data"
                                                            :key="item.insurer_description" class="small">
                                                            <td class="small"><strong>{{ item.insurer_description
                                                            }}</strong>
                                                            </td>
                                                            <td class="small text-right">{{ item.quotes }}</td>
                                                            <td class="small text-right">{{ item.avg_position }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.min_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.max_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.avg_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_admin_fee
                                                            }}
                                                            </td>
                                                            <td class="small text-right">{{
                                                                item.insurer_pcage_of_quotes
                                                            }}%
                                                            </td>
                                                            <td class="small text-right progress-bar-wrapper"
                                                                v-html="item.insurer_pcage_of_quotes_html"></td>
                                                            <td class="small text-right">{{ item.sales }}</td>
                                                            <td class="small text-right">{{ item.avg_sold_position
                                                            }}</td>
                                                            <td class="small text-right">&pound;{{
                                                                item.min_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.max_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_sold_admin_fee
                                                            }} </td>
                                                            <td class="small text-right">{{
                                                                item.insurer_pcage_of_sales
                                                            }}%</td>
                                                            <td class="tiny text-right progress-bar-wrapper"
                                                                v-html="item.insurer_pcage_of_sales_html"></td>
                                                            <td class="small text-right"><strong>&pound;{{
                                                                item.earnings
                                                            }}</strong></td>

                                                        </tr>
                                                    </tbody>

                                                </table>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div class="tab-pane" id="statistics-declines" role="tabpanel"
                                    aria-labelledby="statistics-declines-tab">

                                    <div class="card-body">

                                        <div class="row">

                                            <div class="col-4">

                                                <h4>Last hour</h4>

                                                <table
                                                    class="table table-bordered table-hover table-sm table-striped no-bottom-margin">

                                                    <thead>
                                                        <tr class="small thead-dark">
                                                            <th scope="col" class="small">Decline reason</th>
                                                            <th scope="col" class="small">Quotes</th>
                                                            <th colspan="2" scope="col" class="small">Of quotes</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="(item) in page_data.statistics.quote_decline_reasons_last_hour_data"
                                                            :key="item.quote_reason_consolidated" class="small">
                                                            <td class="small"><strong>{{ item.quote_reason_consolidated
                                                            }}</strong>
                                                            </td>
                                                            <td class="small text-right">{{ item.quotes }}</td>
                                                            <td class="small text-right">{{
                                                                item.percentage
                                                            }}%
                                                            </td>
                                                            <td class="small text-right progress-bar-wrapper"
                                                                v-html="item.percentage_html"></td>

                                                        </tr>
                                                    </tbody>

                                                </table>

                                            </div>

                                            <div class="col-4">

                                                <h4>24 Hours</h4>

                                                <table
                                                    class="table table-bordered table-hover table-sm table-striped no-bottom-margin">

                                                    <thead>
                                                        <tr class="small thead-dark">
                                                            <th scope="col" class="small">Decline reason</th>
                                                            <th scope="col" class="small">Quotes</th>
                                                            <th colspan="2" scope="col" class="small">Of quotes</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="(item) in page_data.statistics.quote_decline_reasons_24_hours_data"
                                                            :key="item.quote_reason_consolidated" class="small">
                                                            <td class="small"><strong>{{ item.quote_reason_consolidated
                                                            }}</strong>
                                                            </td>
                                                            <td class="small text-right">{{ item.quotes }}</td>
                                                            <td class="small text-right">{{
                                                                item.percentage
                                                            }}%
                                                            </td>
                                                            <td class="small text-right progress-bar-wrapper"
                                                                v-html="item.percentage_html"></td>

                                                        </tr>
                                                    </tbody>

                                                </table>

                                            </div>

                                            <div class="col-4">

                                                <h4>All time</h4>

                                                <table
                                                    class="table table-bordered table-hover table-sm table-striped no-bottom-margin">

                                                    <thead>
                                                        <tr class="small thead-dark">
                                                            <th scope="col" class="small">Decline reason</th>
                                                            <th scope="col" class="small">Quotes</th>
                                                            <th colspan="2" scope="col" class="small">Of quotes</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="(item) in page_data.statistics.quote_decline_reasons_all_time_data"
                                                            :key="item.quote_reason_consolidated" class="small">
                                                            <td class="small"><strong>{{ item.quote_reason_consolidated
                                                            }}</strong>
                                                            </td>
                                                            <td class="small text-right">{{ item.quotes }}</td>
                                                            <td class="small text-right">{{
                                                                item.percentage
                                                            }}%
                                                            </td>
                                                            <td class="small text-right progress-bar-wrapper"
                                                                v-html="item.percentage_html"></td>

                                                        </tr>
                                                    </tbody>

                                                </table>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div class="tab-pane" id="statistics-durations" role="tabpanel"
                                    aria-labelledby="statistics-durations-tab">

                                    <div class="card-body">

                                        <div class="row">

                                            <div class="col-1">
                                                <h4>Hours</h4>
                                            </div>

                                            <div class="col-11">

                                                <table
                                                    class="table table-bordered table-hover table-sm table-striped no-bottom-margin">

                                                    <thead>
                                                        <tr class="small thead-dark">
                                                            <th scope="col" class="small">Duration</th>
                                                            <th scope="col" class="small">Quotes</th>
                                                            <th scope="col" class="small">Min. prem.</th>
                                                            <th scope="col" class="small">Max. prem.</th>
                                                            <th scope="col" class="small">Avg. prem.</th>
                                                            <th scope="col" class="small">Avg. fee</th>
                                                            <th colspan="2" scope="col" class="small">Of quotes</th>
                                                            <th scope="col" class="small">Sales</th>
                                                            <th scope="col" class="small">Min. sold premium</th>
                                                            <th scope="col" class="small">Max. sold premium</th>
                                                            <th scope="col" class="small">Avg. sold premium</th>
                                                            <th scope="col" class="small">Avg. sold fee</th>
                                                            <th colspan="2" scope="col" class="small">Of sales</th>
                                                            <th scope="col" class="small">Earnings</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="(item) in page_data.statistics.duration_share_hours_data"
                                                            :key="item.duration" class="small">
                                                            <td class="small"><strong>{{ item.duration
                                                            }}</strong>
                                                            </td>
                                                            <td class="small text-right">{{ item.quotes }}</td>
                                                            <td class="small text-right">&pound;{{ item.min_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.max_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.avg_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_admin_fee
                                                            }}
                                                            </td>
                                                            <td class="small text-right">{{
                                                                item.duration_pcage_of_quotes
                                                            }}%
                                                            </td>
                                                            <td class="small text-right progress-bar-wrapper"
                                                                v-html="item.duration_pcage_of_quotes_html"></td>
                                                            <td class="small text-right">{{ item.sales }}</td>
                                                            <td class="small text-right">&pound;{{
                                                                item.min_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.max_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_sold_admin_fee
                                                            }} </td>
                                                            <td class="small text-right">{{
                                                                item.duration_pcage_of_sales
                                                            }}%
                                                            </td>
                                                            <td class="small text-right progress-bar-wrapper"
                                                                v-html="item.duration_pcage_of_sales_html"></td>
                                                            <td class="small text-right"><strong>&pound;{{
                                                                item.earnings
                                                            }}</strong></td>

                                                        </tr>
                                                    </tbody>

                                                </table>

                                            </div>

                                        </div>

                                    </div>

                                    <div class="card-body">

                                        <div class="row">

                                            <div class="col-1">
                                                <h4>Days</h4>
                                            </div>

                                            <div class="col-11">

                                                <table
                                                    class="table table-bordered table-hover table-sm table-striped no-bottom-margin">

                                                    <thead>
                                                        <tr class="small thead-dark">
                                                            <th scope="col" class="small">Duration</th>
                                                            <th scope="col" class="small">Quotes</th>
                                                            <th scope="col" class="small">Min. prem.</th>
                                                            <th scope="col" class="small">Max. prem.</th>
                                                            <th scope="col" class="small">Avg. prem.</th>
                                                            <th scope="col" class="small">Avg. fee</th>
                                                            <th colspan="2" scope="col" class="small">Of quotes</th>
                                                            <th scope="col" class="small">Sales</th>
                                                            <th scope="col" class="small">Min. sold premium</th>
                                                            <th scope="col" class="small">Max. sold premium</th>
                                                            <th scope="col" class="small">Avg. sold premium</th>
                                                            <th scope="col" class="small">Avg. sold fee</th>
                                                            <th colspan="2" scope="col" class="small">Of sales</th>
                                                            <th scope="col" class="small">Earnings</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="(item) in page_data.statistics.duration_share_days_data"
                                                            :key="item.duration" class="small">
                                                            <td class="small"><strong>{{ item.duration
                                                            }}</strong>
                                                            </td>
                                                            <td class="small text-right">{{ item.quotes }}</td>
                                                            <td class="small text-right">&pound;{{ item.min_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.max_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.avg_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_admin_fee
                                                            }}
                                                            </td>
                                                            <td class="small text-right">{{
                                                                item.duration_pcage_of_quotes
                                                            }}%
                                                            </td>
                                                            <td class="small text-right progress-bar-wrapper"
                                                                v-html="item.duration_pcage_of_quotes_html"></td>
                                                            <td class="small text-right">{{ item.sales }}</td>
                                                            <td class="small text-right">&pound;{{
                                                                item.min_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.max_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_sold_admin_fee
                                                            }} </td>
                                                            <td class="small text-right">{{
                                                                item.duration_pcage_of_sales
                                                            }}%
                                                            </td>
                                                            <td class="small text-right progress-bar-wrapper"
                                                                v-html="item.duration_pcage_of_sales_html"></td>
                                                            <td class="small text-right"><strong>&pound;{{
                                                                item.earnings
                                                            }}</strong></td>

                                                        </tr>
                                                    </tbody>

                                                </table>

                                            </div>

                                        </div>

                                    </div>

                                    <div class="card-body">

                                        <div class="row">

                                            <div class="col-1">
                                                <h4>Weeks</h4>
                                            </div>

                                            <div class="col-11">

                                                <table
                                                    class="table table-bordered table-hover table-sm table-striped no-bottom-margin">

                                                    <thead>
                                                        <tr class="small thead-dark">
                                                            <th scope="col" class="small">Duration</th>
                                                            <th scope="col" class="small">Quotes</th>
                                                            <th scope="col" class="small">Min. prem.</th>
                                                            <th scope="col" class="small">Max. prem.</th>
                                                            <th scope="col" class="small">Avg. prem.</th>
                                                            <th scope="col" class="small">Avg. fee</th>
                                                            <th colspan="2" scope="col" class="small">Of quotes</th>
                                                            <th scope="col" class="small">Sales</th>
                                                            <th scope="col" class="small">Min. sold premium</th>
                                                            <th scope="col" class="small">Max. sold premium</th>
                                                            <th scope="col" class="small">Avg. sold premium</th>
                                                            <th scope="col" class="small">Avg. sold fee</th>
                                                            <th colspan="2" scope="col" class="small">Of sales</th>
                                                            <th scope="col" class="small">Earnings</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="(item) in page_data.statistics.duration_share_weeks_data"
                                                            :key="item.duration" class="small">
                                                            <td class="small"><strong>{{ item.duration
                                                            }}</strong>
                                                            </td>
                                                            <td class="small text-right">{{ item.quotes }}</td>
                                                            <td class="small text-right">&pound;{{ item.min_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.max_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{ item.avg_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_admin_fee
                                                            }}
                                                            </td>
                                                            <td class="small text-right">{{
                                                                item.duration_pcage_of_quotes
                                                            }}%
                                                            </td>
                                                            <td class="small text-right progress-bar-wrapper"
                                                                v-html="item.duration_pcage_of_quotes_html"></td>
                                                            <td class="small text-right">{{ item.sales }}</td>
                                                            <td class="small text-right">&pound;{{
                                                                item.min_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.max_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_sold_premium
                                                            }}
                                                            </td>
                                                            <td class="small text-right">&pound;{{
                                                                item.avg_sold_admin_fee
                                                            }} </td>
                                                            <td class="small text-right">{{
                                                                item.duration_pcage_of_sales
                                                            }}%
                                                            </td>
                                                            <td class="small text-right progress-bar-wrapper"
                                                                v-html="item.duration_pcage_of_sales_html"></td>
                                                            <td class="small text-right"><strong>&pound;{{
                                                                item.earnings
                                                            }}</strong></td>

                                                        </tr>
                                                    </tbody>

                                                </table>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="tab-pane fade" id="map" role="tabpanel" aria-labelledby="map-tab">

                    <div class="alert alert-info fs-7" v-if="show.help" role="alert">
                        <p>
                            This page displays the location of each quotation based on the supplied postcode rather
                            than
                            the IP address, which is not an accurate reflection of location. Quotes from the last
                            hour
                            are shown on the map.
                        </p>
                        <div class="alert alert-info" role="alert">
                            <i class="fas fa-laptop-code" aria-hidden="true"></i> We are working on adding sale
                            markers.
                        </div>
                    </div>

                    <iframe title="Maps" width="100%" height="680"
                        src="https://app.powerbi.com/reportEmbed?reportId=fc5b7b2a-05cc-4cdb-a08a-d0f93cc4675f&autoAuth=true&ctid=3fd808a4-24c2-4305-a256-71abd113cd44&filterPaneEnabled=false"
                        frameborder="0" allowFullScreen="true"></iframe>

                </div>

                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">

                    <div>
                        &nbsp;
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <div class="card text-center" v-if="page_data.profile_data">
                                <div class="card-header bg-dark text-light">
                                    <h5>
                                        <i class="fas fa-user" aria-hidden="true"></i>
                                        Partner/user information
                                    </h5>
                                </div>
                                <div class="card-body">
                                    <p class="card-title lead">{{ page_data.profile_data.partner_name }}</p>
                                    <table class="table table-bordered table-striped table-hover table-sm">
                                        <tbody>
                                            <tr>
                                                <th>Code</th>
                                                <td>
                                                    <code>{{ page_data.profile_data.partner_code }}</code>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Email</th>
                                                <td>{{ page_data.profile_data.user_email }}</td>
                                            </tr>
                                            <tr>
                                                <th>GUID</th>
                                                <td>
                                                    <code>{{ page_data.profile_data.partner_guid }}</code>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>User ID</th>
                                                <td>
                                                    <code>{{ page_data.profile_data.user_id }}</code>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>User name</th>
                                                <td>{{ page_data.profile_data.user_full_name }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End: Main data grid -->


    <!-- Start: Mobile data grid -->
    <div class="container-fluid d-block d-sm-none">

        <iframe title="Console elements" width="100%" height="530"
            src="https://app.powerbi.com/reportEmbed?reportId=d98bcce8-0e3e-4cc8-b714-19278c79ea85&autoAuth=true&ctid=3fd808a4-24c2-4305-a256-71abd113cd44&filterPaneEnabled=false"
            frameborder="0" allowFullScreen="true"></iframe>
    </div>
    <!-- End: Mobile data grid -->


    <!-- Start: Filtering -->
    <nav class="navbar fixed-bottom navbar-dark bg-dark d-none d-sm-block float-right">
        <form class="form-inline" v-on:submit.prevent="set_filter_by_submit()">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <input class="form-control form-control-sm mr-sm-2" type="search" aria-label="Filter" name="filter"
                        id="filter" v-model="paging_and_filtering.filter">
                    <button class="btn btn-outline-light btn-sm my-2 my-sm-0" type="button"
                        v-on:click="set_filter_by_submit">Filter</button>
                    &nbsp;
                    <button class="btn btn-outline-light btn-sm my-2 my-sm-0" type="button" v-on:click="clear_filter">
                        <i class="fas fa-trash-can" aria-hidden="true"></i>
                    </button>
                </li>
            </ul>
        </form>
    </nav>
    <!-- End: Filtering -->
</template>

<script>
import axios from "axios";
import consts from '@/lib/consts';

export default {
    name: 'data-page_data',
    data() {
        return {
            show: {
                help: false
            },
            partner: {
                id: ''
            },
            // Debug is controlled via the database at a login level
            debug: {
                api: false,
                paging_and_filtering: false,
                user: false,
            },
            user: {
                humanised_timestamps: false,
                id: '',
                logout_at: '',
                logout_in: 0,
                now: null,
                session_timeout: '',
                show_warning: false,
                time_left: 0,
                warning_at: '',
                warning_in: 0,
            },
            api: {
                config: '',
                current_tab: '',
                error_count: 0,
                error: '',
                interval: 5000,
                loaded: false,
                paused: false,
                pulse: 0,
                request: '',
                response: '',
                running: false,
                url: '',
            },
            page_data: {
                ataglance_data: {},
                error: {
                    data: null,
                    status: null,
                },
                overview_data: null,
                profile_data: {},
                quotes_data: null,
                sidebars: {
                    transactions_data: null,
                },
                signals_data: null,
                signals_settings: null,
                statistics: {
                    duration_share_days_data: null,
                    duration_share_hours_data: null,
                    duration_share_weeks_data: null,
                    insurer_share_24_hours_data: null,
                    insurer_share_all_time_data: null,
                    insurer_share_last_hour_data: null,
                    quote_decline_reasons_24_hours_data: null,
                    quote_decline_reasons_all_time_data: null,
                    quote_decline_reasons_last_hour_data: null,
                },
                steps_data: null,
                transactions_data: null,
            },
            paging_and_filtering: {
                filter: null,
                filter_shown: false,
                overview_current_page: 1,
                quotes_current_page: 1,
                rows_per_page: 35,
                signals_current_page: 1,
                steps_current_page: 1,
                transactions_current_page: 1,
            },
        }
    },
    methods: {
        update_tab(new_tab) {
            this.api.current_tab = new_tab;
            this.force_load_data();
        },
        toggle_help() {
            this.show.help = !this.show.help;
        },
        show_filter() {
            this.paging_and_filtering.filter_shown = !this.paging_and_filtering.filter_shown;
        },
        goto_top() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        paging(node, direction, pageNumber) {
            console.log('paging', node, direction, pageNumber)

            if (pageNumber !== null) {
                this.paging_and_filtering[node + '_current_page'] = pageNumber
            } else {
                if (direction === 'next') {
                    this.paging_and_filtering[node + '_current_page']++
                } else if (direction === 'previous') {
                    if (this.paging_and_filtering[node + '_current_page'] > 1) {
                        this.paging_and_filtering[node + '_current_page']--
                    }
                }
            }

            this.force_load_data()
        },
        clear_filter: function () {
            let that = this

            that.paging_and_filtering.filter = null
            that.goto_top()
            that.force_load_data()
        },
        set_filter_by_attribute: function (event) {
            let that = this

            that.paging_and_filtering.filter = event.target.getAttribute('data-filter')
            that.goto_top()
            that.force_load_data()
        },
        set_filter_by_submit: function () {
            let that = this

            that.goto_top()
            that.force_load_data()
        },
        set_session: function () {
            let that = this

            that.user.id = sessionStorage.getItem('user.id')
            that.user.logout_at = sessionStorage.getItem('user.timeout.logout.at')
            that.user.session_timeout = sessionStorage.getItem('user.timeout.minutes')
            that.user.warning_at = sessionStorage.getItem('user.timeout.warning.at')
        },
        check_session: function () {
            let that = this

            that.user.now = Math.round(+new Date() / 1000)
            that.user.time_left = that.user.now - that.user.logout_at

            if (that.user.now > that.user.warning_at) {
                that.user.show_warning = true
            } else {
                that.user.warning_in = that.user.now - that.user.warning_at
            }

            if (that.user.now > that.user.logout_at) {
                sessionStorage.clear()
                document.location = '/?sessiontimeout'
            }

            setTimeout(that.check_session, 1000)
        },
        touch_login: function () {
            let that = this

            that.log('touch_login')

            that.api.data = 'sessionGuid=' + sessionStorage.getItem('session.guid')

            that.api.config = {
            }

            that.api.request = JSON.stringify(that.api.data, null, 4)

            that.page_data.error.data = null
            that.page_data.error.status = null

            axios.post(that.api.url + 'touch.asp', that.api.data, that.api.config)
                .then((response) => {
                    sessionStorage.setItem('session.guid', response.data.data.sessionguid)
                    sessionStorage.setItem('user.id', response.data.data.userid)
                    sessionStorage.setItem('user.timeout.logout.at', response.data.data.logoutat)
                    sessionStorage.setItem('user.timeout.minutes', response.data.data.timeout)
                    sessionStorage.setItem('user.timeout.warning.at', response.data.data.warningat)

                    that.set_session()
                    that.user.show_warning = false
                })
        },
        force_load_data: function () {
            let that = this

            that.api.paused = false
            that.api.running = false
            that.api.pulse = 0
            that.load_data()
        },
        load_data: function () {
            let that = this

            this.log('Start: load_data()')

            if (that.api.running === false && that.api.paused === false) {
                this.log('Running Ajax...')

                that.api.running = true // Stop ajax from firing if it takes a while to collect a response

                that.api.url = process.env.VUE_APP_PROXY_URL

                axios.defaults.withCredentials = true

                axios.interceptors.response.use((response) => response, (error) => {
                    if (typeof error.response === 'undefined') {
                        this.log('🧔 ui.vue experienced an Axios CORS/network error')
                        that.api.error_count += 1
                        if (that.api.error_count >= 10) {
                            that.page_data.error.status = 'Network'
                            that.page_data.error.data = consts.constants.axios.network_or_cors
                            that.api.error_count = 0
                        }
                    }
                    return Promise.reject(error)
                })

                that.api.data = {
                    'requested_service': 'release',
                    'data': {
                        'current_tab': that.api.current_tab,
                        'filter': that.paging_and_filtering.filter,
                        'overview_current_page': that.paging_and_filtering.overview_current_page,
                        'pulse': that.api.pulse,
                        'quotes_current_page': that.paging_and_filtering.quotes_current_page,
                        'rows_per_page': that.paging_and_filtering.rows_per_page,
                        'signals_current_page': that.paging_and_filtering.signals_current_page,
                        'steps_current_page': that.paging_and_filtering.steps_current_page,
                        'transactions_current_page': that.paging_and_filtering.transactions_current_page,
                        'user.id': that.user.id,
                    }
                }

                that.api.config = {
                }

                that.api.request = JSON.stringify(that.api.data, null, 4)

                that.page_data.error.data = null
                that.page_data.error.status = null

                this.log('Axios POST started')
                axios.post(that.api.url, that.api.data, that.api.config)
                    .then((response) => {
                        this.log('Axios POST finished')

                        that.api.running = false
                        that.api.loaded = true
                        that.api.error = ''
                        that.api.error_count = 0
                        that.api.response = JSON.stringify(response.data, null, 4)

                        switch (response.data.status) {
                            case 'fail':
                                this.log(' > fail');

                                if (response.data.data.code === '003') {
                                    sessionStorage.removeItem('user.id')
                                    document.location = '/?not_logged_in'
                                }

                                break

                            case 'success':
                                this.log(' > success');
                                this.log(that.api.loaded)

                                // Load partner parameters
                                that.partner.id = response.data.partner_guid
                                that.api.pulse = response.data.pulse

                                // Set debug switches
                                that.debug.api = response.data.debug.api
                                that.debug.paging_and_filtering = response.data.debug.paging_and_filtering
                                that.debug.user = response.data.debug.user

                                // Set user profile switches
                                that.user.humanised_timestamps = response.data.user_profile.humanised_timestamps

                                // Load profile
                                that.page_data.profile_data = response.data.user_profile

                                // If data has changed, then...
                                if (response.data.data_changed === true) {
                                    this.log("Data has changed")
                                    // ...load page...
                                    if (that.api.current_tab == 'Journeys') { that.page_data.overview_data = response.data.overview }
                                    if (that.api.current_tab == 'Quotes') { that.page_data.quotes_data = response.data.quotes }
                                    if (that.api.current_tab == 'Steps') { that.page_data.steps_data = response.data.steps }
                                    if (that.api.current_tab == 'Transactions') {
                                        that.page_data.transactions_data = response.data.transactions
                                        that.page_data.sidebars.transactions_data = response.data.sidebars.transactions
                                    }
                                    // ...statistics
                                    if (that.api.current_tab == 'Statistics') {
                                        that.page_data.statistics.duration_share_days_data = response.data.statistics.duration_share_days
                                        that.page_data.statistics.duration_share_hours_data = response.data.statistics.duration_share_hours
                                        that.page_data.statistics.duration_share_weeks_data = response.data.statistics.duration_share_weeks
                                        that.page_data.statistics.insurer_share_24_hours_data = response.data.statistics.insurer_share_24_hours
                                        that.page_data.statistics.insurer_share_all_time_data = response.data.statistics.insurer_share_all_time
                                        that.page_data.statistics.insurer_share_last_hour_data = response.data.statistics.insurer_share_last_hour
                                        that.page_data.statistics.insurer_share_today_data = response.data.statistics.insurer_share_today
                                        that.page_data.statistics.quote_decline_reasons_24_hours_data = response.data.statistics.quote_decline_reasons_24_hours
                                        that.page_data.statistics.quote_decline_reasons_all_time_data = response.data.statistics.quote_decline_reasons_all_time
                                        that.page_data.statistics.quote_decline_reasons_last_hour_data = response.data.statistics.quote_decline_reasons_last_hour
                                    }
                                } else {
                                    this.log("Data has not changed")
                                }

                                break

                            default:
                                this.log(' > default');

                                that.page_data.error.status = response.data
                        }
                    }, (error) => {
                        that.api.running = false
                        that.api.error = error.response

                        if (error.response) {
                            this.log(error.response)
                            that.page_data.error.data = error.response.data
                            that.page_data.error.status = error.response.status

                            switch (error.response.status) {
                                case 401:
                                    that.user.authenticationerror = 'Credentials not recognised'
                                    sessionStorage.clear()
                                    document.location = '/'

                                    break

                                case 500:
                                    that.api.error = 'A 500 error occured @ ' + that.api.url

                                    break

                                default:
                                    that.user.authenticationerror = 'An error occurred'
                            }
                        }
                    })
            } else {
                this.log('Not running Ajax (paused or already running)')
            }

            this.log('End: load_data()')
        },
        load_data_on_interval: function () {
            let that = this

            setInterval(function () {
                that.load_data()
            }, that.api.interval)
        },
        log: function (message) {
            if (process.env.VUE_APP_DEBUG_CONSOLE === 'True') {
                console.log('%c🧔 %c%s', 'font-size: 90%', 'background-color: DarkGreen; color: White; font-size: 90%', message)
            }
        },
    },
    mounted: function () {
        this.set_session()
        this.check_session()
        this.load_data()
        this.load_data_on_interval()
    },
}
</script>

<style>
body {
    margin-top: 70px;
    /* If using navbar fixed-top */
    margin-bottom: 180px !important;
    /* If using navbar fixed-bottom */
}

pre,
textarea {
    font-size: 9pt;
}

.fs-7 {
    font-size: 9.5pt;
}

.code {
    font-family: "Lucida Console", "Courier New", Courier, monospace;
}

.badge-pill {
    font-size: 9pt !important;
}

.bg-custom-info {
    background-color: green;
}

.bg-custom-warning {
    background-color: darkorange;
}

th {
    text-align: center !important;
}

.no-bottom-margin {
    margin-bottom: 0 !important;
}

.no-bottom-padding {
    padding-bottom: 0 !important;
}

.small-bottom-margin {
    margin-bottom: 4px !important;
}

.tiny {
    font-size: 6.5pt;
}

p.small {
    font-size: 7pt;
}

tr.selected_quote {
    background-color: #b3bee7 !important;
}

.bg-light-custom {
    background-color: #d9dff3 !important;
}

tr.sale {
    border: 3px Green solid;
    background-color: rgb(205, 218, 205) !important;
}

.width-30 {
    width: 30px;
}

.icon-blue {
    color: #0069D9;
}

.error {
    font-family: "Lucida Console", "Courier New", Courier, monospace;
    font-weight: bold;
    font-size: 80%;
    margin: 10px;
    padding: 10px;
}

.new-quote {
    border-top: 3px solid #343A40;
}

.last-quote-row {
    border-style: none !important;
    background-color: none !important;
}

.col {
    padding-left: 5px;
    padding-right: 5px;
}

TD.progress-bar-wrapper {
    width: 50px;
}

DIV.scrolling {
    overflow: auto;
    height: 400px;
    width: 100%;
}
.green-dot, .blue-dot {
    display: inline-block;
    width: 8px; /* Size of the dot */
    height: 8px; /* Size of the dot */
    border-radius: 50%; /* Makes the element a circle */
    margin-right: 2px; /* Space between dot and text */
}

.green-dot {
    background-color: green;
}

.blue-dot {
    background-color: blue;
}
</style>